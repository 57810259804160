import { useApiContinents } from '@/composables/useApiContinents';
import { useUrls } from '@/composables/useUrls';
import { useApiCountries } from '~/composables/useApiCountries';
import { useRoutes } from '~/composables/useRoutes.js';

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) {
    const nuxtApp = useNuxtApp();

    // If the error is already being handled, skip the validation.
    if (nuxtApp.payload.error) {
      return;
    }

    // ## COMPOSABLES
    const { getCountryByName } = useApiCountries();
    const { useRouteManager } = useRoutes();
    const { useUrl } = useUrls();

    const urlParams = to?.params;
    const urlQuery = to?.query;
    const urlPath = to?.path.toLowerCase();

    // VALIDATE PROPERTY TYPE
    // If invalid property type, then default to 'hostels' property type (according to the user language);
    const propertyTypeObj = useRouteManager.validatePropertyTypeParam(urlParams.propertyType);

    // VALIDATE COUNTRY WITH CALL TO SPAPI
    const locationObj = await getCountryByName(propertyTypeObj?.key, urlParams.country);

    if (locationObj?.id) {
      // COUNTRY SEEMS TO BE A VALID ONE.
      // CHECK IF REQUESTED URL MATCHES THE USER LANGUAGE FORMAT
      const countryPageUrlForCurrentLang = useUrl.getCountryPageUrl(
        {
          propertyTypeSlug: propertyTypeObj.slug,
          urlFriendlyContinent: locationObj.urlFriendlyContinent,
          urlFriendlyCountry: locationObj.urlFriendlyName,
        },
        urlQuery,
      );

      const countryPagePathForCurrentLang = new URL(countryPageUrlForCurrentLang).pathname.toLowerCase();

      if (countryPagePathForCurrentLang !== urlPath) {
        // REQUESTED URL DOES NOT MATCH USER LANGUAGE FORMAT
        // REDIRECT USER TO THE APPROPRIATE URL FORMAT
        navigateTo(countryPagePathForCurrentLang, {
          redirectCode: 301,
          external: false,
        });
      }
    } else {
      // COUNTRY IS NOT FOUND, THROW 404 TO CHECK THE REDIRECTION API
      const errorDescription = `### No such Country '${urlParams.country}' in Continent '${urlParams.continent}'`;

      let urlForTranslation = urlPath;
      if (!urlParams.propertyType.match(/\.php$/)) {
        const { getContinentByName } = useApiContinents();

        urlForTranslation = urlPath.replace(`${urlParams.propertyType}/`, `${propertyTypeObj.slug}/`);

        // VALIDATE CONTINENT WITH CALL TO SPAPI
        // We need to validate the continent to be able to distinguish between RWD urls like:
        // /hostels/madrid/huertas/ -> District page -> available on RedirectionAPI and
        // /hostels/europe/madrid/ -> City Page -> not available on RedirectionAPI
        const continentObj = await getContinentByName(propertyTypeObj?.key, urlParams.continent);

        if (continentObj?.id) {
          urlForTranslation = urlForTranslation.replace(`${urlParams.continent}/`, '');
        }
      }

      console.error(errorDescription);

      throw createError({
        statusCode: 404,
        statusMessage: errorDescription,
        data: useRouteManager.enrichedErrorResponse(errorDescription, urlForTranslation),
      });
    }
  }
});
